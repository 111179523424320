export default {
  path: '/slogin',
  name: 'slogin',
  redirect: '/loginHome',
  meta: {
    title: '登录页',
  },
  component: () => import('@views/login/components/stem86Login'),
  children: [
    {
      path: '/loginHome',
      name: 'loginHome',
      meta: {
        title: '登录页首页',
      },
      component: () => import('@views/login/components/stem86Login/loginHome'),
    },
    {
      path: '/queryCertificate',
      name: 'queryCertificate',
      meta: {
        title: '证书查询',
      },
      component: () => import('@views/login/components/stem86Login/queryCertificate'),
    },
    {
      // 注册协议
      path: '/slogin/protocol',
      name: 'slogin/protocol',
      component: () => import('@views/login/protocol'),
    },
  ],
};
