import { personalModelGateWay } from '@/models/personalModel';

const MUTATION_TYPE = {
  SET_USER: 'SET_USER',
  SET_READ_NUMBER: 'SET_READ_NUMBER',
};

const user = {
  namespaced: true,
  state: {
    userInfo: {}, // 当前登录客户信息
    unReadNotificationNumber: 0,
    provinceList: [],
    idCardTypeList: [],
    districtList: [],
    cityList: [],
    bannerList: [],
    vipTypeEnumList: [],
    userLoading: false,
  },
  getters: {
    userInfo: (state) => state.userInfo,
    userLoading: (state) => state.userLoading,
    unReadNotificationNumber: (state) => state.unReadNotificationNumber,
    provinceList: (state) => state.provinceList,
    idCardTypeList: (state) => state.idCardTypeList,
    districtList: (state) => state.districtList,
    cityList: (state) => state.cityList,
    bannerList: (state) => state.bannerList,
    vipTypeEnumList: (state) => state.vipTypeEnumList,
  },
  mutations: {
    [MUTATION_TYPE.SET_USER](state, info) {
      state.userInfo = info;
    },
    setUnReadNotificationNumber(state, info) {
      state.unReadNotificationNumber = info;
    },
    setProvinceList(state, info) {
      state.provinceList = info;
    },
    setIdCardTypeList(state, info) {
      state.idCardTypeList = info;
    },
    setDistrictList(state, info) {
      state.districtList = info;
    },
    setCityList(state, info) {
      state.cityList = info;
    },
    setBannerList(state, info) {
      state.bannerList = info;
    },
    setVipTypeEnumList(state, info) {
      state.vipTypeEnumList = info;
    },
    setLoading(state, info) {
      state.userLoading = info;
    },
  },
  actions: {
    [MUTATION_TYPE.SET_USER](context) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true);
        personalModelGateWay
          .studentGetProfileV2()
          .then((res) => {
            context.commit(MUTATION_TYPE.SET_USER, { ...res.result.user, registrationPopupType: res.result?.registrationPopupType, registrationUrlList: res.result?.registrationUrlList } || {});
            context.commit('setLoading', false);
            context.commit('setUnReadNotificationNumber', res.result.unReadNotificationNumber);
            context.commit('setProvinceList', res.result.provinceList);
            context.commit('setIdCardTypeList', res.result.idCardTypeList);
            context.commit('setDistrictList', res.result.districtList);
            context.commit('setCityList', res.result.cityList);
            context.commit('setBannerList', res.result.bannerList);
            context.commit('setVipTypeEnumList', res.result.vipTypeEnumList);
            resolve(true);
          })
          .catch(() => {
            reject();
          });
      });
    },
    [MUTATION_TYPE.SET_READ_NUMBER](context, payload) {
      context.commit('setUnReadNotificationNumber', payload);
    },
  },
};

export default user;
