// 存储loalStorage
export const loalStorageSet = (key, value) => {
  if (!key) return;
  if (typeof value !== 'string') {
    value = JSON.stringify(value);
  }
  window.localStorage.setItem(key, value);
};
// 获取localStorage
export const loalStorageGet = (key) => {
  if (!key) return;
  return window.localStorage.getItem(key);
};
// 删除localStorage
export const loalStorageRemove = (key) => {
  if (!key) return;
  window.localStorage.removeItem(key);
};
// 清空localStorage
export const loalStorageClear = () => {
  window.localStorage.clear();
};
// 设置cookie
export const setCookie = (key, value, expire) => {
  const d = new Date();
  d.setDate(d.getDate() + expire);
  document.cookie = `${key}=${escape(value)};expires=${d.toUTCString()};path=/;domain=.stem86.com`;
};
// 读取cookie
export const getCookie = (key) => {
  const cookieStr = unescape(document.cookie);
  const arr = cookieStr.split('; ');
  let cookieValue = '';
  for (let i = 0; i < arr.length; i++) {
    const optTemp = arr[i].replace('=', '&&');
    const temp = optTemp.split('&&');
    if (temp[0] === key) {
      cookieValue = unescape(temp[1]);
      break;
    }
  }
  return cookieValue;
};
// 删除cookie
export const delCookie = (key) => {
  document.cookie = `${key}=;expires=${new Date().toUTCString()};path=/;domain=.stem86.com`;
};

// 校验身份证号码
export const checkCardNo = (value) => {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  return reg.test(value);
};
// 校验是否包含中文
export const haveCNChars = (value) => {
  return /[\u4e00-\u9fa5]/.test(value);
};
// 校验是否为邮箱地址
export const isEmail = (value) => {
  return /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value);
};
// 校验手机号
export const isTel = (value) => {
  return /^1[3,4,5,6,7,8,9][0-9]{9}$/.test(value.toString());
};
// 时间转换为string格式
export function currentTimeToString(time) {
  const year = time.getFullYear() < 10 ? `0${time.getFullYear()}` : `${time.getFullYear()}`;
  const month = time.getMonth() + 1 < 10 ? `0${time.getMonth() + 1}` : `${time.getMonth() + 1}`;
  const day = time.getDate() < 10 ? `0${time.getDate()}` : `${time.getDate()}`;
  const hours = time.getHours() < 10 ? `0${time.getHours()}` : `${time.getHours()}`;
  const minutes = time.getMinutes() < 10 ? `0${time.getMinutes()}` : `${time.getMinutes()}`;
  const seconds = time.getSeconds() < 10 ? `0${time.getSeconds()}` : `${time.getSeconds()}`;
  return [year, month, day, hours, minutes, seconds];
}

// 设备判断
function mobileAndTabletCheck() {
  let check = false;
  const a = navigator.userAgent || navigator.vendor || window.opera;
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
      a,
    ) ||
    // eslint-disable-next-line no-useless-escape
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      a.substr(0, 4),
    )
  )
    check = true;
  return check;
}
// 是否chrome 90+版本
function isChromiumVersionMoreThan90() {
  const { userAgent } = navigator;
  const from = userAgent.indexOf('Chrome');
  if (from === -1) {
    // this.tips = '非指定浏览器'
    return false;
  }
  const result = navigator.userAgentData && navigator.userAgentData.brands && navigator.userAgentData.brands.some((b) => b.brand === 'Google Chrome' || b.brand === 'Microsoft Edge');
  if (result === false) {
    return false;
  }
  const to = userAgent.indexOf('.', from);
  const version = userAgent.slice(from + 7, to) - 0;
  // this.tips = version >= 90 ? '支持赛考' : '当前浏览器版本过低, 请升级浏览器或使用谷歌浏览器（最新稳定版）'
  return version >= 90;
}
// 判断是否可以跳转OJ
export function validateChromeBrowser() {
  const isPc = !mobileAndTabletCheck();
  const version = isChromiumVersionMoreThan90();
  return isPc && version;
}

export function queryURLParams(url) {
  const pattern = /(\w+)=(\w+)/gi; // 定义正则表达式
  const parames = {}; // 定义参数对象
  url.replace(pattern, ($, $1, $2) => {
    parames[$1] = $2;
  });
  return parames;
}

export function winOpenTargetUrl(url, userInfo) {
  const { examUserId, categoryId } = userInfo;
  const str = `${examUserId}${categoryId}`;
  const CHILD_WINDOW_NAME = `stem86-考试-${str}`;
  const newurl = `${url}&diff=${str}`;
  let popup = window.open('', CHILD_WINDOW_NAME);
  const params = queryURLParams(newurl);
  if (params.diff == str && popup.location.href !== 'about:blank') {
    console.log('窗口已经存在了~');
    popup.focus();
    // popup.close();
    // popup = window.open(newurl, CHILD_WINDOW_NAME);
  } else {
    console.log('~窗口不存在~');
    popup = window.open(newurl, CHILD_WINDOW_NAME);
  }
  return popup;
}

// 跳转oj
export function timerInterPostMessageToSQ(url, userInfo, childName) {
  const CHILD_WINDOW_NAME = childName;
  const popup = window.open(url, CHILD_WINDOW_NAME);
  // console.log('打印传递给sq的消息', JSON.stringify(userInfo));
  // popup.postMessage(JSON.stringify(userInfo), url);
  window.timerInterPostMessageToSq = {
    timerInter: null,
    clearTimerInter: () => {
      clearInterval(window.timerInterPostMessageToSq.timerInter);
    },
  };
  let n = 1;
  window.timerInterPostMessageToSq.timerInter = setInterval(() => {
    if (n > 10) {
      window.timerInterPostMessageToSq.clearTimerInter();
    }
    console.log('打印传递给sq的消息', n.toFixed, JSON.stringify(userInfo));
    popup.postMessage(JSON.stringify(userInfo), url);
    n++;
  }, 500);
}

export const getOjUrl = `${window.location.protocol}//${
  // eslint-disable-next-line no-nested-ternary
  process.env.NODE_ENV === 'production' ? 'oj' : process.env.NODE_ENV === 'pre' ? 'gray-oj' : 'test-oj'
}.stem86.com/#/home`;

export function debounce(func, wait = 500, immediate = false) {
  let timeout;
  let context;
  let result;
  let callNow;

  function showResult(e1, e2) {
    result = func.apply(e1, e2);
    return result;
  }

  return function (...args) {
    context = this;
    if (timeout) clearTimeout(timeout);
    if (immediate) {
      callNow = !timeout;
      timeout = setTimeout(function () {
        timeout = null;
      }, wait);
      if (callNow) return showResult(context, args);
    } else {
      timeout = setTimeout(function () {
        clearTimeout(timeout);
        return showResult(context, args);
      }, wait);
    }
    return result;
  };
}

export function throttle(func, wait, options) {
  let timeout;
  let context;
  let args;
  // let result;
  let previous = 0;
  if (!options) options = {};

  const later = function () {
    previous = options.leading === false ? 0 : new Date().getTime();
    timeout = null;
    func.apply(context, args);
    if (!timeout) {
      args = null;
      context = args;
    }
  };

  const throttled = function () {
    const now = new Date().getTime();
    if (!previous && options.leading === false) previous = now;
    const remaining = wait - (now - previous);
    context = this;
    // eslint-disable-next-line prefer-rest-params
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      func.apply(context, args);
      if (!timeout) {
        args = null;
        context = args;
      }
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
  };

  throttled.cancel = function () {
    clearTimeout(timeout);
    previous = 0;
    timeout = null;
  };

  return throttled;
}

// 等级考试等级转换为中文汉字
export function numberConversionToChineseCharacters(n) {
  const charactersArray = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八'];
  return charactersArray[n];
}
