import SlsTracker from '@aliyun-sls/web-track-browser';

const agentOpts = {
  host: 'cn-beijing.log.aliyuncs.com',
  project: 'stem86-front-end',
  logstore: 'oj-log',
  topic: 'student-agent',
  source: 'student-platform',
  tags: {
    // 日志标签信息
    tags: 'student-platform',
  },
};
const agentTracker = new SlsTracker(agentOpts);
export default agentTracker;
