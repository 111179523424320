<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { loalStorageGet } from '@/utils/common.js';

const { mapGetters } = createNamespacedHelpers('user');

export default {
  name: 'App',
  computed: {
    ...mapGetters(['userInfo']),
  },
  created() {
    document.oncontextmenu = function (event) {
      event.returnValue = false;
      return false;
    };
    const that = this;
    window.addEventListener('visibilitychange', function () {
      console.log('visibilitychange', document.visibilityState, that);
      if (document.visibilityState === 'visible') {
        if (loalStorageGet('studentUuid') && that.userInfo.uuid !== loalStorageGet('studentUuid')) {
          that.$store.dispatch('user/SET_USER');
        }
      }
    });
  },
};
</script>
