<template>
  <div class="content">
    <LqAliVideo ref="lqAliVideo" visible autoPlay :playInfo="options" @playing="handlePause" />
    <!-- <BuyMemberDialog ref="buyMemberDialog" /> -->
  </div>
</template>

<script>
import LqAliVideo from '@/components/alivideo';
// import BuyMemberDialog from '@/components/buyMemberDialog/index.vue';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('user');

export default {
  components: {
    // BuyMemberDialog,
    LqAliVideo,
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    const prismPlayer = document.querySelector('div[id^="aliVideo"]');
    const watermarkFixed = document.createElement('div');
    watermarkFixed.className = 'video-watermark-fixed';
    watermarkFixed.innerHTML = ` <span class="name">${this.options.version}&nbsp;</span>
                                 <span class="uuid">${this.options.date}</span>`;
    const watermarkMove = document.createElement('div');
    watermarkMove.className = 'video-watermark-move';
    watermarkMove.innerHTML = ` <span class="name">${this.options.name}&nbsp;</span>
                                <span class="uuid">${this.options.uuid}</span>`;
    prismPlayer.appendChild(watermarkMove);
    prismPlayer.appendChild(watermarkFixed);
    console.log(this.options);
  },
  beforeUnmount() {
    // 课程视频播放进度
    window.$vueApp.$sa.click({
      click_element: 'WEB_STUDENT_COURSE_PAGE_VIDEO',
      progress: parseFloat((this.$refs.lqAliVideo.getCurrentTime() / this.$refs.lqAliVideo.getDuration()).toFixed(4)),
    });
  },
  methods: {
    handlePause(value) {
      console.info('handlePause', value, this.$refs);
      if (value === false) {
        if (!this.userInfo.studentMemberShipInfo.canAccessComputational) {
          // this.$refs.buyMemberDialog.openDialog();
        }
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.content {
  min-width: 1148px;
  height: 100%;
  background: #000
  :v-deep(.video-watermark-fixed) {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 12;
    padding: 20px 40px;
    user-select: none;
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    -webkit-text-stroke: 1px #979797;
    text-stroke: 1px #979797;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.33);
    opacity: 0.3;
  }
  :v-deep(.video-watermark-move) {
    position: absolute;
    z-index: 12;
    user-select: none;
    top: calc(50% - 100px);
    left: 50%;
    transform: translateX(-50%) rotate(310deg);
    font-weight: 400;
    color: #fff;
    -webkit-text-stroke: 1px #979797;
    text-stroke: 1px #979797;
    opacity: 0.3;
    .name {
      font-size: 60px;
      line-height: 60px;
    }
    .uuid {
      font-size: 35px;
      display: block;
    }
  }
}
</style>
