<template>
  <div class="lq-video" v-if="visible">
    <div class="prism-player" id="aliVideo">
      <div class="flow-video" id="flowVideo" @click.prevent="play"></div>
    </div>
  </div>
</template>

<script>
import { $emit } from '../../utils/gogocodeTransfer';
import aliplayer from '@/utils/aliplayer';

export default {
  name: 'LqAliVideo',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // 传递播放对象
    playInfo: {
      type: Object,
      default: () => {},
    },
    // 是否开启记忆播放
    isRecordPlayTime: {
      type: Boolean,
      default: false,
    },
    autoPlay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lqAliplayder: null,
      // lastPlayTime: localStorage.getItem('lastPlayTime'),
      // recordTimer: null,
    };
  },
  watch: {
    visible: {
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            this.initAliVideo();
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    // 初始化播放器和播放记录节点
    async initAliVideo() {
      this.lqAliplayder = await aliplayer(this.playInfo, 'aliVideo', this.isRecordPlayTime, this.autoPlay);
      this.registerPlayerEvent();
    },
    // 注册监听事件
    registerPlayerEvent() {
      const _video = document.getElementById('flowVideo');
      const that = this;
      this.lqAliplayder.on('play', function () {
        _video.removeEventListener('click', that.play);
        _video.addEventListener('click', that.pause);
        $emit(that, 'playing', true);
      });
      this.lqAliplayder.on('pause', function () {
        _video.removeEventListener('click', that.pause);
        _video.addEventListener('click', that.play);
        $emit(that, 'playing', false);
      });
      this.lqAliplayder.on('ended', function () {
        $emit(that, 'playEnd');
      });
    },
    pause() {
      this.lqAliplayder.pause();
    },
    play() {
      this.lqAliplayder.play();
    },
    // 获取视频当前播放时长
    getCurrentTime() {
      return this.lqAliplayder.getCurrentTime();
    },
    // 获取视频总时长
    getDuration() {
      return this.lqAliplayder.getDuration();
    },
    fullScreen() {
      console.log('双击');
      if (this.lqAliplayder.fullscreenService.getIsFullScreen()) {
        this.lqAliplayder.fullscreenService.cancelFullScreen();
      } else {
        this.lqAliplayder.fullscreenService.requestFullScreen();
      }
    },
  },
  unmounted() {
    this.$el.remove();
    window.aliplayer = null;
    this.lqAliplayder = null;
  },
  emits: ['playing', 'playEnd'],
};
</script>

<style lang="stylus">
@import './index.styl';
</style>
