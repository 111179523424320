import { loalStorageClear } from '@/utils/common.js';
import { ElMessage as Message } from 'element-plus';
import NProgress from 'nprogress'; // 引入nprogress插件
import 'nprogress/nprogress.css';
import ServiceHttp from './service-http';
import SymbolMessage from './service-http/symbolMessage';
// 对象实例
import vm from '../../main';

const symbolMessage = new SymbolMessage();
const baseApi = process.env.VUE_APP_BASE_API;

export default class HTTP extends ServiceHttp {
  constructor(newBaseApi) {
    super({
      options: {
        baseApi: newBaseApi || baseApi,
        timeout: 20000,
      },
      defaultHeaders: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Cache-Control': 'no-cache', // 不使用强缓存
      },
    });
  }

  request(config) {
    return new Promise((resolve, reject) => {
      // 请求开始
      NProgress.start();
      const { isTransformResponse } = config;
      this.service(config)
        .then((res) => {
          if (isTransformResponse) {
            resolve(res);
            return;
          }
          if (res.code === this.HTTPSTATUS.SUCCESS || res.code === 100) {
            resolve(res);
          } else if ([this.HTTPSTATUS.INVALID_TOKEN, this.HTTPSTATUS.INVALID_AUTHORIZATION, this.HTTPSTATUS.INVALID_TOKEN_GATEWAY, this.HTTPSTATUS.INVALID_AUTHORIZATION_GATEWAY].includes(res.code)) {
            loalStorageClear();
            symbolMessage.warning({
              message: res.message,
              type: 'warning',
            });
            vm.$sa.logOut();
            const teacherDns = window.location.hostname.split('.')[0];
            if (['xs', 'student', 'gray-xs', 'test2-stem86'].includes(teacherDns)) {
              vm.config.globalProperties.$router.push({ path: '/loginHome' });
            } else {
              vm.config.globalProperties.$router.push({ path: '/login' });
            }
            reject(res);
          } else {
            Message.error(res.message);
            reject(res);
          }
        })
        .catch((error) => {
          Message.error(error.message);
          reject();
        })
        .finally(() => {
          // 请求结束，隐藏loading
          NProgress.done();
        });
    });
  }
}
