<template>
  <div class="pdf" @scroll="pdfScroll">
    <div class="nav-top">
      <div class="title">{{ studentCourseWareContent.title }}</div>
      <div class="op">
        <div class="addBtn" @click="scaleD" id="addBtn">＋</div>
        <div class="scaleNum">{{ scaleNum }} %</div>
        <div class="subBtn" @click="scaleX" id="subBtn">－</div>
        <div class="separate"></div>
        <div class="pageInfo">{{ pageNum }} / {{ pageTotalNum }}</div>
      </div>
    </div>
    <div class="watermark">
      <p class="version-validity">
        <span>{{ studentCourseWareContent.version }}&nbsp;</span>
        <span>{{ studentCourseWareContent.date }}</span>
      </p>
      <p class="name-uuid">
        <span class="teacherName">{{ studentCourseWareContent.name }}&nbsp;</span><br />
        <span class="teacherUUID">{{ studentCourseWareContent.uuid }}</span>
      </p>
    </div>
    <VuePdf v-for="page in pageTotalNum" ref="pdf" :key="page" :src="studentCourseWareContent.url" :page="page" :style="{ display: 'inline-block', width: scaleNum + '%' }" />
    <!-- <object
      type="application/pdf"
      :data="studentCourseWareContent.url"
      style="width: 100%; height: 100vh"
    >
      <p>
        当前浏览器不支持查看此PDF文件，请下载之后查看：
        <a :href="studentCourseWareContent.url">下载PDF</a>
      </p>
    </object> -->
  </div>
</template>

<script>
import { VuePdf, createLoadingTask } from 'vue3-pdfjs/esm';

export default {
  components: { VuePdf },
  data() {
    return {
      pageNum: 1,
      pageTotalNum: 1,
      pageRotate: 0,
      // 加载进度
      loadedRatio: 0,
      curPageNum: 0,
      scaleNum: 100,
      studentCourseWareContent: null,
      selectCourseWareContentId: -1,
      currentScrollPercent: 0, // pdf进度
    };
  },

  created() {
    const that = this;
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      that.handleRecordProgress();
    });
    this.studentCourseWareContent = JSON.parse(window.$vueApp.config.globalProperties.$route.query.studentGetCoursewareObj);
    this.$nextTick(() => {
      this.getNumPages();
    });
  },

  methods: {
    // 视频播放进度/pdf埋点
    handleRecordProgress() {
      // 讲义滚动条下拉埋点
      window.$vueApp.$sa.click({
        click_element: 'WEB_STUDENT_SYSTEM_COURSE_DETAIL_HANDOUT_SCROLL',
        process: this.currentScrollPercent,
        new_course_ware_id: window.$vueApp.config.globalProperties.$route.query.new_course_ware_id,
      });
    },
    // 上一页函数，
    prePage() {
      let page = this.pageNum;
      page = page > 1 ? page - 1 : this.pageTotalNum;
      this.pageNum = page;
    },

    // 下一页函数
    nextPage() {
      let page = this.pageNum;
      page = page < this.pageTotalNum ? page + 1 : 1;
      this.pageNum = page;
    },
    // 页面顺时针翻转90度。
    clock() {
      this.pageRotate += 90;
    },

    // 页面逆时针翻转90度。
    counterClock() {
      this.pageRotate -= 90;
    },

    // 页面加载回调函数，其中e为当前页数
    pageLoaded(e) {
      this.curPageNum = e;
    },

    // 其他的一些回调函数。
    pdfError(error) {
      console.error(error);
    },
    // 计算pdf页码总数
    getNumPages() {
      const loadingTask = createLoadingTask(this.studentCourseWareContent.url);
      loadingTask.promise
        .then((pdf) => {
          this.pageTotalNum = pdf.numPages;
        })
        .catch((err) => {
          console.error('pdf 加载失败', err);
        });
    },
    scaleD() {
      if (this.scaleNum === 100) return;
      this.scaleNum += 5;
    },
    scaleX() {
      if (this.scaleNum === 10) return;
      this.scaleNum += -5;
    },
    pdfScroll(e) {
      const { scrollTop } = e.target;
      const pdfHeight = this.$refs.pdf[0].$el.offsetHeight;
      this.pageNum = Math.floor(scrollTop / pdfHeight) + 1;
      this.currentScrollPercent = parseFloat((e.target.scrollTop / (e.target.scrollHeight - e.target.offsetHeight)).toFixed(4));
    },
  },

  beforeUpdate() {
    this.$arrRefs && (this.$arrRefs = {});
  },
};
</script>

<style lang="stylus" scoped>
.pdf {
  height: 747px;
  width: 1440px;
  // position: absolute;
  // top: 220px;
  // left: 50%;
  // right: 0;
  overflow: scroll;
  // background: rgb(244 244 244);
  // z-index: 1;
  // transform: scale(1) translate(-50%);
  span {
    margin: 0px auto;
    margin-bottom: 10px;
  }
  .nav-top {
    display: flex;
    position: fixed;
    width: 100%;
    height: 48px;
    top: 0px;
    background: rgba(0, 0, 0, 0.36);
    z-index: 10;
    .title {
      width: 433px;
      height: 50px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      line-height: 48px;
      margin-left: 347px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .op {
      display: flex;
      margin-right: 359px;
      margin-left: auto;
      div {
        margin: auto 0px;
      }
      .addBtn, .subBtn {
        width: 40px;
        height: 32px;
        background: #94bf32;
        border-radius: 8px;
        color: white;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
      }
      .scaleNum {
        width: 60px;
        height: 32px;
        background: #ffffff;
        border-radius: 8px;
        border: 2px solid #94bf32;
        font-size: 14px;
        font-weight: bold;
        color: #a1700f;
        line-height: 32px;
        text-align: center;
        box-sizing: border-box;
        margin: auto 10px;
      }
      .separate {
        width: 1px;
        height: 20px;
        background: #d8d8d8;
        margin: auto 10px;
      }
      .pageInfo {
        width: 40px;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
  .page-bottom {
    position: absolute;
    bottom: 17px;
    right: 134px;
    width: 49px;
    height: 49px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.4);
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    line-height: 49px;
    z-index: 10;
    .pageTotalNum {
      font-size: 14px;
    }
  }
}
.watermark {
  user-select: none;
  color: #fff;
}
.version-validity {
  position: fixed;
  top: 150px;
  right: 40px;
  z-index: 2;
}
.name-uuid {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%) rotate(-45deg);
  span {
    font-size: 40px !important;
  }
}
.watermark span {
  font-size: 18px;
  font-weight: 400;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.33);
  -webkit-text-stroke: 1px #979797;
  text-stroke: 1px #979797;
  opacity: 0.3;
}
:deep(canvas) {
    display: inline-block;
    width: 100%
    height: auto
}
</style>
