export default {
  path: 'liteClass',
  name: 'liteClass',
  component: () => import('@views/liteClass'),
  meta: {
    title: '亲子轻课',
  },
  children: [
    {
      path: 'course',
      name: 'liteClass/course',
      meta: {
        title: '亲子轻课',
      },
      component: () => import('@views/liteClass/course'),
      children: [
        {
          path: 'list',
          name: 'liteClass/course/list',
          meta: {
            title: '亲子轻课列表',
          },
          component: () => import('@views/liteClass/course/list'),
        },
        {
          path: 'detail',
          name: 'liteClass/course/detail',
          meta: {
            title: '亲子轻课详情',
          },
          component: () => import('@views/liteClass/course/detail'),
        },
      ],
    },
  ],
};
