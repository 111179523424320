import HTTP from '@/utils/http';

const env = require('../../../env.json');

class PersonalModel extends HTTP {
  constructor() {
    super();
    if (!PersonalModel.instance) {
      PersonalModel.instance = this;
    }
    return PersonalModel.instance;
  }
  /**
   * @name: hanxiaoding
   * @test: test font
   * @see https://e.gitee.com/i-clever/docs/748678/file/1659641?sub_id=4523231
   * @msg: 取消实名认证接口
   * @param {*}
   * @return {*}
   */
  studentCancelRealNameAuth(params) {
    return this.post({
      data: params,
    });
  }

  /**
   * @name: wp
   * @test: test font
   * @see https://thoughts.aliyun.com/workspaces/646c1d147f5bcd001341a65c/docs/6678d3b85e11940001ca397d#667d09e0fc4b7f5a1dc47818
   * @msg: 学生获取项目合作相关枚举
   * @param {*}
   * @return {*}
   */
  getCooperationEnum(params) {
    return this.post({
      data: params,
    });
  }
  /**
   * @name: wp
   * @test: test font
   * @see https://thoughts.aliyun.com/workspaces/646c1d147f5bcd001341a65c/docs/6678d3b85e11940001ca397d#667d09e0fc4b7f5a1dc47818
   * @msg: 学生获取项目合作相关数据
   * @param {*}
   * @return {*}
   */
  studentGetProjectRecommendList(params) {
    return this.post({
      data: params,
    });
  }
}
class PersonalModelGateWay extends HTTP {
  constructor() {
    super(env[process.env.VUE_APP_ENV].VUE_APP_BASE_API_V2);
    if (!PersonalModelGateWay.instance) {
      PersonalModelGateWay.instance = this;
    }
    return PersonalModelGateWay.instance;
  }
  // 更新密码
  studentUpdatePassword(params) {
    return this.post({
      url: '/student/account/studentUpdatePassword',
      data: params,
    });
  }
  // 获得轮播图
  studentGetBannerList(params) {
    return this.get({
      url: '/student/common/studentGetBannerList',
      params,
    });
  }
  // 学生获得消息列表
  studentGetNotificationList(params) {
    return this.get({
      url: '/student/common/studentGetNotificationList',
      params,
    });
  }
  // 学生端查看消息通知
  studentViewNotification(params) {
    return this.post({
      url: '/student/common/studentViewNotification',
      data: params,
    });
  }
  // 取消实名
  studentCancelRealNameAuthentication(params) {
    return this.post({
      url: '/student/profile/studentCancelRealNameAuthentication',
      data: params,
    });
  }
  // 查询证书
  studentGetCertificate(params) {
    return this.get({
      url: '/student/profile/studentGetCertificate',
      params,
    });
  }
  // 获取学生基本信息
  studentGetProfileV2(params) {
    return this.get({
      url: '/student/profile/studentGetProfileV2',
      params,
      isTransformResponse: true,
    });
  }
  // 获得学生实名认证信息
  studentGetRealNameAuthenticationInfo(params) {
    return this.get({
      url: '/student/profile/studentGetRealNameAuthenticationInfo',
      params,
    });
  }
  // 提交学生实名认证信息
  studentSubmitRealNameAuthentication(params) {
    return this.post({
      url: '/student/profile/studentSubmitRealNameAuthentication',
      data: params,
    });
  }
  // 编辑机构ID
  studentUpdateAgencyId(params) {
    return this.post({
      url: '/student/profile/studentUpdateAgencyId',
      data: params,
    });
  }
  /**
   * @name: hanxiaoding
   * @test: test font
   * @see https://e.gitee.com/i-clever/docs/748678/file/1659641?sub_id=4523231
   * @msg: 更新学生基本信息
   * @param {*}
   * @return {*}
   */
  studentUpdateProfileV2(params) {
    return this.post({
      url: '/student/profile/studentUpdateProfileV2',
      data: params,
    });
  }
  /**
   * @name: xiaozheng
   * @test: test font
   * @see https://thoughts.aliyun.com/workspaces/646c1d147f5bcd001341a65c/docs/658b88ab4c60500001f3f336#659bbd1950767d9c0b4d13f9
   * @msg: 学生获取会员推荐的相关信息
   * @param {*}
   * @return {*}
   */
  studentGetMembershipRecommend(params) {
    return this.get({
      url: '/student/computationalThinking/studentGetMembershipRecommend',
      params,
    });
  }
}

const personalModel = new PersonalModel();
const personalModelGateWay = new PersonalModelGateWay();

export { personalModel, personalModelGateWay };
