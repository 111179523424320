import { createRouter, createWebHashHistory } from 'vue-router';
// import store from '../store';
import ErrorPage from './error-page';
import SLogin from './sLogin';
import ActivityOneLeval from './activity/oneLevalRouter';
import Home from './home';
import CoursewarePdf from '@views/classroom/components/CoursewarePdf.vue';
import CoursewareMedia from '@views/classroom/components/CoursewareMedia.vue';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/selfTransfer',
      name: 'selfTransfer',
      meta: {
        title: '计算思维测评落地页',
      },
      component: () => import('@views/selfTransfer'),
    },
    {
      path: '/destinationScratch',
      name: 'destinationScratch',
      meta: {
        title: 'scratch测评落地页',
      },
      component: () => import('@views/destination/index.vue'),
    },
    {
      path: '/destinationPython',
      name: 'destinationPython',
      meta: {
        title: 'python测评落地页',
      },
      component: () => import('@views/destination/index.vue'),
    },
    {
      path: '/destinationCpp',
      name: 'destinationCpp',
      meta: {
        title: 'c++测评落地页',
      },
      component: () => import('@views/destination/index.vue'),
    },
    {
      path: '/destinationThinking',
      name: 'destinationThinking',
      meta: {
        title: '计算思维测评落地页',
      },
      component: () => import('@views/destination/index.vue'),
    },
    {
      path: '/double11',
      name: 'double11',
      meta: {
        title: '研习社双11落地页',
      },
      component: () => import('@views/double11'),
    },
    {
      path: '/double12',
      name: 'double12',
      meta: {
        title: '研习社双11落地页',
      },
      component: () => import('@views/double12'),
    },
    ...ActivityOneLeval,
    {
      path: '/loadingPage',
      name: 'loadingPage',
      meta: {
        title: '中转页',
      },
      component: () => import('@views/loadingPage'),
    },
    {
      path: '/analysis',
      name: 'analysis',
      meta: {
        title: '答题解析',
      },
      component: () => import('@views/liteClass/analysis'),
    },
    { path: '/:pathMatch(.*)', redirect: '/404', hidden: true },
    {
      path: '/xes-speiyou',
      name: 'xes-speiyou',
      component: () => import('@views/xes-speiyou/index'),
    },
    ...ErrorPage,
    {
      path: '/login',
      name: 'login',
      meta: {
        title: '登录页',
      },
      component: () => import('@views/login'),
    },
    {
      path: '/posterPreview',
      name: 'posterPreview',
      meta: {
        title: '海报预览页',
      },
      component: () => import('@views/login/teacherCustomPage/components/posterPreview'),
    },
    {
      path: '/poster',
      name: 'poster',
      meta: {
        title: '生成海报',
      },
      component: () => import('@views/login/teacherCustomPage/components/poster/index'),
    },
    {
      // 注册协议
      path: '/protocol',
      name: 'protocol',
      component: () => import('@views/login/protocol'),
    },
    {
      path: '/coursewarePdf',
      name: 'coursewarePdf',
      component: CoursewarePdf,
    },
    {
      path: '/coursewareMedia',
      name: 'coursewareMedia',
      component: CoursewareMedia,
    },
    {
      path: '/registrationPage',
      name: 'registrationPage',
      meta: {
        title: '注册成功页',
      },
      component: () => import('@views/login/components/registrationPage'),
    },
    SLogin, // 特定域名下的路由文件配置
    Home,
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 }; // 期望滚动到哪个的位置
    }
  },
});

export default router;
