export default [
  {
    path: '/activity/analysis',
    name: 'activityAnalysis',
    meta: {
      title: '题目讲解',
    },
    component: () => import('@views/activity/analysis'),
  },
  {
    path: '/activity/ranking',
    name: 'activityRanking',
    meta: {
      title: '排名',
    },
    component: () => import('@views/activity/ranking'),
  },
  {
    path: '/activity/parentChildReading',
    name: 'activityParentChildReading',
    meta: {
      title: '亲子共读',
    },
    component: () => import('@views/activity/parentChildReading'),
  },
  {
    path: '/activity/detail',
    name: 'activityDetail',
    meta: {
      title: '活动地图',
    },
    component: () => import('@views/activity/detail'),
  },
  {
    path: '/activity/task',
    name: 'activityTask',
    meta: {
      title: '闯关',
    },
    component: () => import('@views/activity/task'),
  },
];
