/**
 * http响应状态码
 */

export default {
  SUCCESS: 0, // 你的操作已成功
  FAIL: 1, // 失败
  INVALID_REQUEST: 101, //  网络不稳定，请稍候重试。
  INVALID_API: 102, // 系统已升级，请在浏览器中强制刷新页面后重试。
  INVALID_TOKEN: 103, // 您的登录信息已经失效，请重新登录。
  INVALID_AUTHORIZATION: 104, // 您的权限不足以执行此项操作，请联系管理员授权。
  INVALID_PARAMETER: 105, // 系统已升级，请在浏览器中强制刷新页面后重试。
  INVALID_DATA: 106, // 您请求的数据存在问题，请重新提交请求。
  TOO_MANY_REQUEST: 107, // 您的请求过于频繁，请稍后重试。
  INVALID_TOKEN_GATEWAY: 401, // 未登录、token过期
  INVALID_AUTHORIZATION_GATEWAY: 403, // 权限不足
  INTERNAL_ERROR: 500, // 系统升级中，请稍后在浏览器中强制刷新页面后重试。
};
