class LqAliplayder {
  constructor(playInfo, elementId, isRecordPlayTime, autoplay) {
    this.playInfo = playInfo;
    this.elementId = elementId;
    this.isRecordPlayTime = isRecordPlayTime;
    this.autoplay = autoplay;
  }
  _initPlayer() {
    /* eslint-disable */
    if (window.aliplayer) {
      window.aliplayer = null;
    }
    return new Promise((resolve) => {
      const saveTime = function (memoryVideo, currentTime) {
        localStorage.setItem(memoryVideo, currentTime);
      };

      const getTime = function (memoryVideo) {
        //return返回的是自定义起播时间
        return localStorage.getItem(memoryVideo);
      };

      const components = [];
      if (this.isRecordPlayTime) {
        components.push({
          name: 'MemoryPlayComponent',
          type: window.AliPlayerComponent.MemoryPlayComponent,
          args: [false, getTime, saveTime],
        });
      }
      window.aliplayer = new Aliplayer(
        /* eslint-enable */
        {
          id: this.elementId, // 播放器id
          width: '100%', // 播放器宽度
          height: '100%', // 播放器高度
          autoplay: this.autoplay || false, // 是否自动播放
          vid: this.playInfo.vid, // vid,阿里云播放视频的凭证
          playauth: this.playInfo.playauth, // 阿里云全县播放的凭证
          cover: this.playInfo.cover, // 播放器的封面
          mediaType: this.playInfo.mediaType || 'video', // 指定返回音频还是视频，只有使用vid的播放方式时支持，默认值为video
          encryptType: 0, // 当播放私有加密流时需要设置。
          qualitySort: 'asc',
          isLive: false, // 播放内容是否为直播，直播时会禁止用户拖动进度条。
          rePlay: false, // 播放器是否自动循环播放。
          showBuffer: true, // 显示播放时缓冲图标，默认值为true。
          language: 'zh-cn', // 国际化，默认为zh-cn。如果未设置，则采用浏览器语言
          snapshot: true, // flash启用截图功能。
          showBarTime: 2000, // 控制栏自动隐藏时间（ms）
          // controlBarVisibility: 'always', // 控制蓝是否一致展示
          preload: true,
          definition: 'FD,LD,SD,HD,OD,2K,4K', // 显示视频清晰度
          defaultDefinition: 'FD,LD,SD,HD,OD,2K,4K', // 默认视频清晰度
          loadDataTimeout: 20, // 缓冲多长时间后，提示用户切换低清晰度，默认：20秒
          waitingTimeout: 60, // 最大缓冲超时时间，超过这个时间会有错误提示，默认：60秒。
          vodRetry: 3, // 点播失败重试次数，默认3次 。
          // format: 'mp4',
          components,
        },
        function (player) {
          player.setVolume(0.5);
          console.log('播放器创建好了。', this.autoPlay);
          resolve(player);
        },
      );
    });
  }
}
export default async function (playInfo, elementId, isRecordPlayTime, autoPlay) {
  const lqAliplayder = new LqAliplayder(playInfo, elementId, isRecordPlayTime, autoPlay);
  const aliplayer = await lqAliplayder._initPlayer();
  return aliplayer;
}
