import Exam from '../exam';
import LiteCourse from '../liteClass/course';
import Activity from '../activity';
import MemberZoneV2 from '../memberZoneV2';

export default {
  path: '/',
  redirect: 'home',
  component: () => import('@/components/layout'),
  children: [
    {
      path: 'home',
      name: 'home',
      meta: {
        title: '首页',
      },
      component: () => import('@views/home'),
    },
    {
      path: 'personalcenter',
      name: 'personalcenter',
      meta: {
        title: '个人中心',
      },
      component: () => import('@views/personalcenter'),
    },
    {
      path: 'myorder',
      name: 'myorder',
      meta: {
        title: '我的订单',
      },
      component: () => import('@views/myorder'),
    },
    {
      path: 'classroom',
      name: 'classroom',
      meta: {
        title: '课程',
      },
      component: () => import('@views/classroom'),
    },
    // {
    //   path: 'member',
    //   name: 'member',
    //   meta: {
    //     title: 'VIP会员专区',
    //     keepAlive: true, // 需要缓存
    //   },
    //   component: () => import('@views/member'),
    // },
    {
      path: 'memberPay',
      name: 'memberPay',
      meta: {
        title: '会员支付界面',
      },
      component: () => import('@views/memberPay'),
    },
    ...Exam, // 考试相关
    LiteCourse, // 亲子轻课课程相关
    Activity, // 计算思维活动相关
    ...MemberZoneV2, // 会员权益拆分
  ],
};
