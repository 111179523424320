// 会员专区
export default [
  // VIP会员页面
  // {
  //   path: 'memberV2/vip',
  //   name: 'memberV2/vip',
  //   meta: {
  //     title: 'VIP会员',
  //   },
  //   component: () => import('@views/memberV2/vip'),
  // },
  // 会员支付页面
  {
    path: 'memberV2/memberPay',
    name: 'memberV2/memberPay',
    meta: {
      title: '会员支付',
    },
    component: () => import('@views/memberV2/memberPay'),
  },
];
