export default {
  path: 'activity',
  name: 'activity',
  component: () => import('@views/activity'),
  meta: {
    title: '计算思维活动',
  },
  children: [
    {
      path: 'list',
      name: 'activity/list',
      meta: {
        title: '列表',
      },
      component: () => import('@views/activity/list'),
    },
  ],
};
